import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import SuspenseSpinner from "./shared/SuspenseSpinner";
import { routeList, privateRouteList } from "./routes";


const Error404 = lazy(() => import("./pages/NotFound/NotFound"))

const AppRoutes = (props) => {
  const { userData } = props;
  return (
    <Suspense fallback={<SuspenseSpinner />}>
      <Routes>
        {routeList.map((val) => (
          <Route
            key={val.path}
            path={val.path}
            element={<val.component />}
            exact={val.exact}
          />
        ))}
        {privateRouteList.map((val) => <Route
          key={val.path}
          path={val.path}
          element={userData?.loggedIn ? <val.component /> : <Navigate to="/login" />}
          exact={val.exact}
        />)}
        <Route path="/" exact element={<Navigate to="/dashboard" />} />
        <Route path="*" exact element={userData?.loggedIn ? <Error404 /> : <Navigate to="/login" />} />
      </Routes>
    </Suspense >
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
  };
};

export default connect(mapStateToProps)(AppRoutes);
