import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../helper/withRouter";
import { Collapse, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { getJobListVista, getUnitedTransactionRental } from "../../services";
import { connect } from "react-redux";
import { sanitizeObj } from "../../helper/function";
import qs from "query-string";
import { SUPPLY_CHAIN_MANAGER_LINK } from "../../constants/supplyChainManager/link";
import AdminSection from "./parts/AdminSection";

const getFeatures = process.env.REACT_APP_FEATURES;
const arrayFeatures = getFeatures?.split(", ");

const EnergySidebar = (props) => {
  const { navigate, userData, location } = props;
  const [state, setState] = useState({
    isJobLoading: false,
    isCheckingUnitedRental: false,
  });

  const { isCheckingUnitedRental } = state;

  const isPathActive = (path) => {
    const checkPath = location.pathname.startsWith(path);
    return checkPath;
  };

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState((prevState) => ({ ...prevState, [menuState]: false }));
    } else if (Object.keys(state).length === 0) {
      setState((prevState) => ({ ...prevState, [menuState]: true }));
    } else {
      const allState = state;

      // //Checking for the from admin is have sub menu
      if (menuState.includes("adminSubMenu")) {
        delete allState["admin"];
        setState((prevState) => ({ ...prevState, admin: true }));
      }
      //Checking for the menu from vdc is have sub menu
      if (
        menuState.includes("batchPlan") ||
        menuState.includes("task_scheduler") ||
        menuState.includes("spooling")
      ) {
        delete allState["vdc"];
        setState((prevState) => ({ ...prevState, vdc: true }));
      }
      //Checking for the menu from Supply Chain Catalog is have sub menu
      if (
        menuState?.includes("requisitionList")
      ) {
        delete allState["supplyChainCatalog"];
        setState((prevState) => ({ ...prevState, supplyChainCatalog: true }));
      }
      //Checking for the menu from supply chain manager is have sub menu
      if (menuState.includes("adminSubMenuMasterMaterialRecords") || menuState.includes("adminSubMenuNewRecord")) {
        delete allState["adminSubMenuSupplyChain"];
        delete allState["adminSubMenuNewRecord"];
        setState((prevState) => ({
          ...prevState,
          adminSubMenuSupplyChain: true,
        }));
      }

      //Checking for the menu from new records, exisitng records and map records is have sub menu
      if (
        menuState.includes("adminSubMenuMasterMaterialNewRecords") ||
        menuState.includes("adminSubMenuMasterMaterialExistingRecords") ||
        menuState.includes("adminSubMenuMasterMaterialMapRecords") 
      ) {
        delete allState["adminSubMenuMasterMaterialRecords"];
        delete allState["adminSubMenuSupplyChain"];
        setState((prevState) => ({
          ...prevState,
          adminSubMenuMasterMaterialRecords: true,
          adminSubMenuSupplyChain: true,
        }));
      }

      Object.keys(allState).forEach((i) => {
        if (!i.includes("is")) {
          setState((prevState) => ({ ...prevState, [i]: false }));
        }
      });

      setState((prevState) => ({ ...prevState, [menuState]: true }));
    }
  };

  const handleChangeJob = async (selected) => {
    if (selected) {
      const toSummary = {
        jobName: selected.label,
        jobId: selected.value,
      };
      const query = {
        page: 1,
        is_admin: "False",
        job_number: selected.value,
        is_check: true,
      };
      setState((prevState) => ({
        ...prevState,
        isCheckingUnitedRental: true,
      }));
      await getUnitedTransactionRental(qs.stringify(sanitizeObj(query)), {
        parseBooleans: true,
      });
      setState((prevState) => ({
        ...prevState,
        isCheckingUnitedRental: false,
      }));
      const toSummaryURL = new URLSearchParams(toSummary).toString();
      window.location = `/field-summary?${toSummaryURL}`;
    }
  };

  const _fetchJobOptions = async (jobId) => {
    try {
      const res = await getJobListVista(jobId);
      const jobOptions = res?.data?.map((val) => ({
        label: `${val.vista_id} ${val.description}`,
        value: val.vista_id,
      }));

      return jobOptions;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    //Prevent for collapsed admin sub menu after reload
    if (isPathActive("/admin")) {
      toggleMenuState("admin");
    }

    //Prevent for collapsed admin sub menu mastering material after reload
    if (isPathActive("/admin/catalog") || isPathActive("/admin/mastering") ||
      isPathActive(SUPPLY_CHAIN_MANAGER_LINK)) {
      toggleMenuState("adminSubMenuMasterMaterialRecords");
    }

    //Prevent for collapsed field catalog material after reload
    if (isPathActive("/catalog")) {
      toggleMenuState("catalogApp");
    }
    
    //Prevent for collapsed receiving after reload
    if (isPathActive("/receiving")) {
      toggleMenuState("receiving");
    }

    if (
      isPathActive("/admin/material-import-log")
    ) {
      toggleMenuState("adminSubMenuSupplyChain");
    }

    if (isPathActive("/task-scheduler")) {
      toggleMenuState("task_scheduler");
    }

    if (isPathActive("/supply-chain-catalog")) {
      toggleMenuState("supplyChainCatalog");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <nav className="sidebar sidebar-fixed sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={
            isPathActive("/dashboard") ? "nav-item active" : "nav-item"
          }
        >
          <Form noValidate className="pt-3">
            <AsyncSelect
              isDisabled={isCheckingUnitedRental}
              placeholder="Enter Job #"
              loadOptions={debounce(_fetchJobOptions, 250, {
                leading: true,
              })}
              onChange={handleChangeJob}
              classNamePrefix="job-select"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {isCheckingUnitedRental && (
              <p className="text-white text-center mt-2">Please Wait...</p>
            )}
          </Form>
        </li>
        {arrayFeatures?.includes("Batch Tracker") && (
          <li
            className={
              isPathActive("/batch-tracker") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                state.appsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => {
                toggleMenuState("appsMenuOpen");
                navigate("/batch-tracker/app");
              }}
              data-toggle="collapse"
            >
              <i className="ti-layout menu-icon"></i>
              <span className="menu-title">Batch Tracker</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.appsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/batch-tracker/app")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/batch-tracker/app"
                  >
                    App
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/batch-tracker/csv-export")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/batch-tracker/csv-export"
                  >
                    CSV Export
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        )}
        {arrayFeatures?.includes("Supply Chain Catalog") && (
          <li
            className={
              isPathActive("/supply-chain-catalog/")
              ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                state.supplyChainCatalog ? "nav-link" : "nav-link"
              }
              onClick={() => {
                toggleMenuState("supplyChainCatalog");
                navigate("/supply-chain-catalog/search-materials");
              }}
            >
              <i className="ti-layout menu-icon"></i>
              <span className="menu-title">Supply Chain Catalog</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.supplyChainCatalog}>
              <ul className="nav flex-column sub-menu">
                {arrayFeatures?.includes("Requisition List") && (
                  <li className="nav-item">
                    <div
                      className={
                        isPathActive("/supply-chain-catalog/requisition-list") ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        toggleMenuState("requisitionList");
                        navigate("/supply-chain-catalog/requisition-list/material-requisition");
                      }}
                    >
                      <span className="menu-title">
                      <Link className="menu-title" to="/supply-chain-catalog/requisition-list/vdc-requisition">
                          Requisition List
                      </Link>
                      </span>
                      <i className="menu-arrow"></i>
                    </div>
                <Collapse in={state.requisitionList}>
                      <ul
                        className="nav flex-column sub-menu"
                        style={{ marginLeft: "-30px" }}
                      >
                        <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            isPathActive("/supply-chain-catalog/requisition-list/material-requisition")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/supply-chain-catalog/requisition-list/material-requisition"
                        >
                          Material Requisitions
                        </Link>
                      </li>
                      </ul>
                    </Collapse>
                    </li>
                )}
                {arrayFeatures?.includes("Search Materials") && (
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/supply-chain-catalog/search-materials")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/supply-chain-catalog/search-materials"
                  >
                    Search Materials
                  </Link>
                </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {arrayFeatures?.includes("Receiving") && (
          <li
            className={
              isPathActive("/receiving") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                state.receiving ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => {
                toggleMenuState("receiving");
                navigate("/receiving/app");
              }}
              data-toggle="collapse"
            >
              <i className="ti-package menu-icon"></i>
              <span className="menu-title">Receiving</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.receiving || state.report}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/receiving/app")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/receiving/app"
                  >
                    App
                  </Link>
                </li>
                <li
                  className={
                    location.pathname?.includes("/admin/material")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <div
                    className={"nav-link"}
                    onClick={() => {
                      toggleMenuState("report");
                    }}
                  >
                    <span className="menu-title">Reports</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={state.report}>
                    <ul
                      className="nav flex-column sub-menu"
                      style={{ marginLeft: "-30px" }}
                    >
                      {arrayFeatures?.includes("Backorder Report") && (
                        <li className="nav-item">
                          <Link
                            className={
                              isPathActive("/receiving/backorder-report")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/receiving/backorder-report"
                          >
                            Backorder
                          </Link>
                        </li>
                      )}
                      {arrayFeatures?.includes("OTIF Report") && (
                        <li className="nav-item">
                          <Link
                            className={
                              isPathActive("/receiving/otif-report")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/receiving/otif-report"
                          >
                            OTIF
                          </Link>
                        </li>
                      )}
                      {arrayFeatures?.includes("Receiving Report") && (
                        <li className="nav-item">
                          <Link
                            className={
                              isPathActive("/receiving/csv-export")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/receiving/csv-export"
                          >
                            Receiving
                          </Link>
                        </li>
                      )}
                      {arrayFeatures?.includes("Tracking Receipts") && (
                        <li className="nav-item">
                          <Link
                            className={
                              isPathActive("/receiving/tracking-receipt-report")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/receiving/tracking-receipt-report"
                          >
                            Tracking Receipts
                          </Link>
                        </li>
                      )}
                      {arrayFeatures?.includes("Production Planning") && (
                        <li className="nav-item">
                          <Link
                            className={
                              isPathActive(
                                "/receiving/receiving-for-production-planning"
                              )
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/receiving/receiving-for-production-planning"
                          >
                            Production <br /> Planning Receipts
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            </Collapse>
          </li>
        )}
        {userData?.user?.is_superuser && (
          <AdminSection
            isPathActive={isPathActive}
            toggleMenuState={toggleMenuState}
            stateMenu={state}
            userData={userData}
          />
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
  };
};

export default connect(mapStateToProps)(withRouter(EnergySidebar));
