import React from "react"
import Proptypes from 'prop-types'
import { isEmpty } from "lodash";
export default function InventoryOnhand(props) {
    const { data } = props

    if (isEmpty(data)) {
        return <></>
    }

    if (data) {
        const locationOnHand = [...data];
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                fontSize: '14px'
            }}>
                <table cellPadding={5} >
                    <thead>
                        <tr>
                            <th>QOH</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            locationOnHand?.splice(0, 2).map((loc) => (
                                <tr>
                                    <td>{loc?.qty || '-'}</td>
                                    <td>{loc?.location || '-'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

InventoryOnhand.propTypes = {
    data: Proptypes.any.isRequired
}