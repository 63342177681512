import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
} from "./../types";

const initState = {
  loading: false,
  loggedIn: false,
  user: {},
  errMessage: "",
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        loggedIn: false,
        user: {},
        errMessage: "",
      };
    case USER_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: { ...action.payload },
        loading: false,
        errMessage: "",
      };
    case USER_LOGIN_ERROR:
      return {
        loggedIn: false,
        user: {},
        loading: false,
        errMessage: action?.errMessage ?? "",
      };
    case USER_LOGOUT:
      return {
        loggedIn: false,
        user: {},
        loading: false,
        errMessage: "",
      };
    default:
      return state;
  }
};

export default userReducer;
