import React from "react"
import PropTypes from 'prop-types'
export const ShimerringLoading = (props) => {
    const { padding, styles } = props
    return (
        <div className="shimmerBG" style={{
            padding: `${padding ? padding : '20'}px`,
            borderRadius: '8px',
            ...styles
        }} />
    )
}

ShimerringLoading.propTypes = {
    padding: PropTypes.string,
    styles: PropTypes.object
}