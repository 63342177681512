const USER_BUSINESS_UNIT = {
    'mechanical': '1',
    'water': '2',
    'service': '3',
    'steel': '4',
    'electrical': '5',
    'energy': '6',
    'mission critical': '7'
};

// this is for localstorage key user business unit
const RK_DIVISION_KEY = 'rkDivision';

const LIST_DIVISION = [
    {
      id: 1,
      label: 'Mechanical',
    },
    {
      id: 2,
      label: 'Water',
    },
    {
      id: 3,
      label: 'Service',
    },
    {
      id: 4,
      label: 'Steel',
    },
    {
      id: 5,
      label: 'Electrical',
    },
    {
      id: 6,
      label:'Energy',
    },
    {
      id: 7,
      label: 'Mission Critical',
    },
];
  

export {
  RK_DIVISION_KEY,
  LIST_DIVISION,
  USER_BUSINESS_UNIT
}