import isEmpty from 'lodash/isEmpty'
import { baseURL, baseURLCelery } from ".";
import qs from 'query-string'


//Checking if env CELERY BACKEND URL is exist
const isExistCeleryURL = !isEmpty(process.env.REACT_APP_CELERY_BACKEND_URL)

const optimizeBaseURL = isExistCeleryURL ? baseURLCelery : baseURL

//Mastering Masterial Retrive & Save
const getMasteringMaterialEditor = (rkNumber) => {
    return optimizeBaseURL.get(`/mastering-editor/detail/${rkNumber}`).then((res) => res.data);
};
const createMasteringMaterialEditor = ({ idSelect, payload }) => {
    return optimizeBaseURL.post(`/mastering-editor/${!idSelect ? '' : `?id_select=${idSelect}`}`, payload).then((res) => res.data);
};
const updateMasteringMaterialEditor = (id_item_relate, payload) => {
    return optimizeBaseURL.patch(`/mastering-editor/update/${id_item_relate}`, payload).then((res) => res.data);
};

export const getFetchDataUsingMDM = ({ url, params }) => {
    return optimizeBaseURL.get(`${url}${params ? `?${qs.stringify(params)}` : ''}  `).then((res) => res.data);
};


export const getMaterialDetail = (materialId) => {
    return optimizeBaseURL
        .get(`/FieldCatalog/material/${materialId}/detail`)
        .then((res) => res.data);
};

export {
    getMasteringMaterialEditor,
    createMasteringMaterialEditor,
    updateMasteringMaterialEditor,
}