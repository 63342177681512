import React from "react";
import Proptypes from 'prop-types'
export default function Gap(props) {
    const { type = 'height', size = '10px' } = props
    return (
        <div style={{ [type]: size }} />
    )
}

Gap.propTypes = {
    type: Proptypes.oneOf(['height', 'width']),
    size: Proptypes.string
}