import React, { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { useWindowSize } from "./../helper/hooks";

export const Pagination = (props) => {
  const { loading, listParams, handleListParams, pagination } = props;

  const screenSize = useWindowSize();
  const screenMobile = screenSize?.width <= 400 ? true : false;

  return (
    <div className="pagination">
      {!loading && (
        <div
          className="shared-filter_list"
          style={{
            display: screenMobile ? "block" : "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "6px 0",
            }}
          >
            {/* <p>
        {pageSize * pageIndex + 1}-
        {canNextPage ? pageSize * (pageIndex + 1) : tableData?.length} of{" "}
        {pagination?.count}
      </p> */}
            <p>{`${pagination?.start_index ?? ""} - ${
              pagination?.end_index ?? ""
            } of ${pagination?.count ?? ""}`}</p>
            <span style={{ margin: "0 8px" }}>|</span>
            <p>Showing</p>
            <Select
              isSearchable={false}
              value={[listParams?.page_size].map((val) => ({
                label: val,
                value: val,
              }))}
              options={[
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              classNamePrefix="select-filter"
              onChange={(selected) => {
                handleListParams("page_size", selected.value);
                // setPageSize(selected.value);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="list-number-pagination">
            <div className="pagination-label">
              <div
                className="list-pagination"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "6px 0",
                }}
              >
              <i
                className={`ti-control-backward`}
                style={{ pointerEvents: listParams?.page !== 1 ? "visible" : "none" }}
                onClick={() => 
                  listParams?.page !== 1
                      ? handleListParams("page", listParams?.page - 1)
                      : null
                }
              />
              <button 
                type='button'
                style={{ pointerEvents: listParams?.page !== 1 ? "visible" : "none" }}
                onClick={() => 
                  handleListParams("page", 1)
                }
              >
                First
              </button>
              {(pagination.current_page == pagination.num_pages) && (pagination.current_page > 1) && (<span>...</span>)}
              <button 
                className="active"
                type="button"
                onClick={() => 
                  handleListParams("page", pagination.current_page)
                }
              >
                {pagination.current_page}
              </button>
                { pagination.current_page + 1 <= pagination.num_pages && (<button 
                  type="button"
                  onClick={() => 
                    handleListParams("page", pagination.current_page + 1)
                  }
                >
                  {pagination.current_page + 1}
                </button>)}
                { pagination.current_page + 2 <= pagination.num_pages ? (<button 
                  type="button"
                  onClick={() => 
                    handleListParams("page", pagination.current_page + 2)
                  }
                >
                  {pagination.current_page + 2}
                </button>) : ""}
              { (pagination.current_page <= pagination.num_pages) && (pagination.current_page != pagination.num_pages) && (<span>...</span>)}
              <button 
                type='button'
                style={{ pointerEvents: listParams?.page !== pagination?.num_pages ? "visible" : "none" }}
                onClick={() => 
                  handleListParams("page", pagination.num_pages)
                }
              >
                Last
              </button>
              <i
                className={`ti-control-forward`}
                style={{ pointerEvents: listParams?.page !== pagination?.num_pages ? "visible" : "none" }}
                onClick={() => 
                  handleListParams("page", pagination.current_page + 1)
                }
              />
                {/* <i
            className={`ti-control-skip-backward ${
              !canPreviousPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => gotoPage(0)}
          /> */}
                {/* <i
            className={`ti-control-backward ${
              !canPreviousPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => previousPage()}
          /> */}
                {/* <i
                  className={`ti-control-backward ${
                    listParams?.page !== 1
                      ? "hover-pointer"
                      : "btn-control-disabled"
                  }`}
                  onClick={() =>
                    listParams?.page !== 1
                      ? handleListParams("page", listParams?.page - 1)
                      : null
                  }
                />
                <p className="pagination-label">
                  Page {pagination?.current_page ?? ""} of{" "}
                  {pagination?.num_pages ?? ""}
                </p>
                <i
                  className={`hover-pointer ti-control-forward ${
                    listParams?.page !== pagination?.num_pages
                      ? "hover-pointer"
                      : "btn-control-disabled"
                  }`}
                  onClick={() =>
                    listParams?.page !== pagination?.num_pages
                      ? handleListParams("page", listParams?.page + 1)
                      : null
                  }
                /> */}
                {/* <i
            className={`ti-control-forward ${
              !canNextPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => nextPage()}
          /> */}
                {/* <i
            className={`ti-control-skip-forward ${
              !canNextPage ? "btn-control-disabled" : ""
            }`}
            onClick={() => gotoPage(pageCount - 1)}
          /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
