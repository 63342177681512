import { STAGING_LIST_FILTER } from "./../types";

const initState = {
  jobId: {},
  filterShop: [],
};

const stagingReducer = (state = initState, action) => {
  switch (action.type) {
    case STAGING_LIST_FILTER:
      return {
        jobId: action.payload?.jobId ?? {},
        filterShop: action.payload?.filterShop ?? [],
      };
    default:
      return state;
  }
};

export default stagingReducer;
