import { useState, useEffect, useRef } from "react";
import { getFetchData, getUserList } from "../services";
import { getFetchDataUsingMDM } from "../services/adminMasteringMaterialEditor";
import qs from 'query-string'
// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export function useEnv() {
  const api = process.env.REACT_APP_BACKEND_URL;
  const env = api.includes("rk-production-server") ? "prod" : "dev";

  return env;
}


export const useDebounce = (value, milliSeconds) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};

export function useEnvLayoutForemanAccess() {
  const api = process.env.REACT_APP_ASSIGNER_LAYOUT_FOREMAN;
  const env = api.includes("rk-production-server") ? "prod" : "dev";
  return env;
}


/**
 * Custom hook for fetching user list
 */
export const useFetchUserList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [optionsContact, setOptionsContact] = useState([])

  useEffect(() => {
    const fetchContact = async () => {
      setIsLoading(true);
      try {
        const res = await getUserList();
        console.log(res)
        if (res) {
          setOptionsContact(
            res?.results?.map((val) => ({
              label: `${val.first_name} - ${val.last_name} - (${val.email})`,
              value: `${val.id}`,
            }))
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContact()
  }, [])
  return [optionsContact, isLoading]
}


// Check page is first rendered
export const useFirstRender = () => {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

// custom hooks for modal 
export const useModal = () => {
  const [openModal, setOpenModal] = useState(false)

  const handleCloseModal = () => setOpenModal(false)

  const handleOpenModal = () => setOpenModal(true)

  return [
    openModal,
    handleCloseModal,
    handleOpenModal
  ]
}

//Custom Hooks for fetching data
export function useFetchingData(data) {
  const { url, params, initiallyLoad = true } = data
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState({
    error: false,
    errMsg: ''
  })
  const [stateList, setStateList] = useState([])

  const handleFetching = async () => {
    try {
      setIsLoading(true)
      const listParamsUrl = {
        url,
        params
      }
      const result = await getFetchData(listParamsUrl)
      setStateList(result)
    } catch (e) {
      setIsError({
        error: true,
        errMsg: ''
      })
    } finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (!initiallyLoad) return;
    handleFetching()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    list: stateList,
    handleRefetch: handleFetching,
    isLoading,
    isError
  }
}


export function useFetchingDataMDM(data) {
  const { url, params, initiallyLoad = true } = data
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState({
    error: false,
    errMsg: ''
  })
  const [stateList, setStateList] = useState([])

  const handleFetching = async () => {
    try {
      setIsLoading(true)
      const listParamsUrl = {
        url,
        params
      }
      const result = await getFetchDataUsingMDM(listParamsUrl)
      setStateList(result)
    } catch (e) {
      setIsError({
        error: true,
        errMsg: ''
      })
    } finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (!initiallyLoad) return;
    handleFetching()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    list: stateList,
    handleRefetch: handleFetching,
    isLoading,
    isError
  }
}