import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Table } from "..";
import Proptypes from 'prop-types';

export default function ModalTable(props) {
    const { modalData, onHide, tableHeader, modalTitle } = props;
    
    let tableData = modalData?.data ?? [];

    return (
        <Modal
            show={modalData?.isOpen}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table 
                    tableHeader={tableHeader} 
                    tableData={tableData}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


ModalTable.propTypes = {
    modalData: Proptypes.any.isRequired,
    onHide: Proptypes.func.isRequired,
    tableHeader: Proptypes.any.isRequired,
    modalTitle: Proptypes.node.isRequired
}