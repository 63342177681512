import React from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { ShimerringLoading } from "./Loading";
import PropTypes from "prop-types";

export const Input = (props) => {
  const { label, rows, style } = props;

  return (
    <div style={style} className="shared-input">
      {label && <p>{label}</p>}
      {!rows ? (
        <input {...props} />
      ) : (
        <textarea rows={rows} {...props}>
          {props.value}
        </textarea>
      )}
    </div>
  );
};

export const InputNumber = (props) => {
  const { label, style } = props;
  return (
    <div style={style} className="shared-input">
      {label && <p>{label}</p>}
      <NumberFormat {...props} />
    </div>
  );
};

export const InputWrapper = (props) => {
  const { label, required, children, isLoading } = props;

  return (
    <div className="shared-input-wrapper">
      <Row>
        <Col md={4} style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontWeight: "500" }}>
            {label}
            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </p>
        </Col>
        <Col md={8}>
          {isLoading ? (
            <ShimerringLoading
              padding="20"
              styles={{
                borderRadius: "8px",
              }}
            />
          ) : (
            children
          )}
        </Col>
      </Row>
    </div>
  );
};

InputWrapper.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};
