import userReducer from "./userReducer";
import stagingReducer from "./stagingReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  userReducer,
  stagingReducer,
});

export default rootReducer;
