import React, { useEffect, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useSortBy, useTable, usePagination } from "react-table";
import Select from "react-select";
import { useWindowSize } from "./../helper/hooks";
import PaginationForTableComponent from "./PaginationForTableComponent";

export const Table = (props) => {
  const { tableData, tableHeader, loading, handleSortTableByParams, topNavigation } = props;

  const screenSize = useWindowSize();
  const screenMobile = screenSize?.width <= 400 ? true : false;

  const data = React.useMemo(() => tableData ?? [], [tableData]);
  const columns = React.useMemo(() => tableHeader ?? [], [tableHeader]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageSize: 100 } }, useSortBy, usePagination);

  const previousPageSizeData = sessionStorage.getItem("previousPageSize");

  // set previous page size value on table data
  useEffect(() => {
    sessionStorage.clear();
    if(previousPageSizeData) {
      setPageSize(Number(previousPageSizeData))
    }
  }, []);
  useMemo(() => {
    sessionStorage.setItem('previousPageSize', pageSize)
  }, [pageSize])

  return (
    <>
    <PaginationForTableComponent 
        topNavigation={topNavigation}
        screenMobile={screenMobile } 
        pageSize={pageSize} 
        pageIndex={pageIndex}
        canNextPage={canNextPage} 
        tableData={tableData}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage} 
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        nextPage={nextPage} 
    />
      <div style={{ overflowX: "auto" }}>
        <table {...getTableProps()} className="shared-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (column) => (
                    (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        onClick={() => handleSortTableByParams(column.id) }
                      >
                        <span>
                          {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            className="ti-arrow-down"
                            style={{ fontSize: "12px", marginRight: "3px" }}
                          />
                        ) : (
                          <i
                            className="ti-arrow-up"
                            style={{ fontSize: "12px", marginRight: "3px" }}
                          />
                        )
                      ) : (
                        <i
                          className="ti-exchange-vertical"
                          style={{ fontSize: "12px", marginRight: "3px" }}
                        />
                      )} */}
                          <i
                            className="ti-exchange-vertical"
                            style={{
                              fontSize: "12px",
                              marginRight: "6px",
                              color: "#2c3e50",
                              display: column.disableSortBy == true ? "none" : "inline-block"
                            }}
                          />
                        </span>
                        {column?.show == false ? null : column.render("Header")}
                      </th>
                    )
                  )
                )}
              </tr>
            ))}
          </thead>
          {!loading && (
            <tbody {...getTableBodyProps()}>
              {/* {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })} */}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell?.column?.show == false ? null : cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {loading && (
            <tbody>
              <tr>
                <td
                  colSpan={tableHeader.length}
                  style={{ height: "100px", textAlign: "center" }}
                >
                  <Spinner variant="primary" animation="border" />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <PaginationForTableComponent 
        topNavigation={false}
        screenMobile={screenMobile } 
        pageSize={pageSize} 
        pageIndex={pageIndex}
        canNextPage={canNextPage} 
        tableData={tableData}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage} 
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        nextPage={nextPage} 
    />
    </>
  );
};
