import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
} from "./../types";
import { microsoftAuth } from "./../../services";
import { setToken } from "./../../services/auth/AuthToken";

export const userLoginAction = (payload) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const res = await microsoftAuth(payload);
    if (res.status === true) {
      const data = res?.data;
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      setToken(data?.token);
    } else {
      dispatch({
        type: USER_LOGIN_ERROR,
        errMessage: res?.message ?? "Unknown error occured. Please try again.",
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_ERROR,
      errMessage: "Unknown error occured. Please try again.",
    });
  }
};

export const userLogoutAction = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
};
