import React, { Component, Suspense, lazy } from "react";
import { withRouter } from "./helper/withRouter";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import { SuspenseSpinner } from "./shared";
import { ToastContainer } from "react-toastify";
const Sidebar = lazy(() => import("./shared/Sidebar"))

const Footer = lazy(() => import("./shared/Footer"))

const Navbar = lazy(() => import("./shared/Navbar"))

const SettingsPanel = lazy(() => import("./shared/SettingsPanel"))

class App extends Component {
  state = {};
  componentDidMount() {
    this.onRouteChanged();
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ""
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <Suspense fallback={<SuspenseSpinner />}>
        <div className="container-scroller">
          {navbarComponent}
          <div className="container-fluid page-body-wrapper">
            {sidebarComponent}
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes />
                <ToastContainer />
                {SettingsPanelComponent}
              </div>
              {footerComponent}
            </div>
          </div>
        </div>

      </Suspense>


    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);

    const fullPageLayoutRoutes = [
      "/login",
      "/register",
      "/forgot-password",
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/reset-password/",
      "/microsoft/auth-callback/",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withRouter(App);
