import React from "react";
import { useWindowSize } from "./../helper/hooks";

export const Page = (props) => {
  const { title, subtitle, className, children, side } = props;
  const screenSize = useWindowSize();
  const screenMobile = screenSize?.width <= 400 ? true : false;

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: !screenMobile ? "32px" : "24px",
              textAlign: !screenMobile ? "left" : "center",
            }}
          >
            {title}
          </p>
          <p
            style={{
              fontSize: !screenMobile ? "20px" : "18px",
              textAlign: !screenMobile ? "left" : "center",
            }}
          >
            {subtitle}
          </p>
        </div>
        {side}
      </div>
      {children}
    </div>
  );
};
