import React from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { Gap } from '../../'
import AdminSupplyChain from "./AdminSupplyChain";
export default function AdminSection(props) {
    const { userData, stateMenu, toggleMenuState, isPathActive } = props
    const navigate = useNavigate()
    const getAdminFeatures = process.env.REACT_APP_ADMIN_FEATURES;
    const arrayAdminFeatures = getAdminFeatures?.split(", ");


    return (
        <>
            <li >
                <div
                    style={{
                        padding: '0.75rem 1rem 0rem 1rem',
                    }}
                >
                    <span style={{

                        fontSize: '16px',
                        color: '#c2c2c3',
                        opacity: '40%',
                        fontWeight: '600',
                        marginBottom: '10px'
                    }}>Admin</span>
                </div>
            </li>
            {arrayAdminFeatures?.includes("Activity Dashboard") && (
                <li className={
                    isPathActive("/admin/activity-dashboard")
                        ? "nav-item active"
                        : "nav-item"
                }>
                    <div
                        className="nav-link"
                        onClick={() =>
                            navigate("/admin/activity-dashboard")
                        }
                    >
                        <i className="ti-clipboard menu-icon"></i>
                        <span className="menu-title">
                            Activity Dashboard
                        </span>
                    </div>
                </li>
            )}
            {arrayAdminFeatures?.includes("Help Tickets") && (
                <li className={
                    isPathActive("/admin/help-tickets")
                        ? "nav-item active"
                        : "nav-item"
                }>
                    <div
                        className="nav-link"
                        onClick={() => navigate("/admin/help-tickets")}
                    >
                        <i className="ti-help-alt menu-icon"></i>
                        <span className="menu-title">
                            Help Tickets
                        </span>
                    </div>
                </li>
            )}
            {arrayAdminFeatures?.includes("Job Summary") && (
                <li className={
                    isPathActive("/admin/summary")
                        ? "nav-item active"
                        : "nav-item"
                }>
                    <div
                        className="nav-link"
                        onClick={() => navigate("/admin/summary")}
                    >
                        <i className="ti-layout-list-thumb menu-icon"></i>
                        <span className="menu-title">
                            Job Summary
                        </span>
                    </div>
                </li>
            )}

            <AdminSupplyChain
                arrayAdminFeatures={arrayAdminFeatures}
                stateMenu={stateMenu}
                toggleMenuState={toggleMenuState}
                isPathActive={isPathActive}
            />
            
            {(userData?.user?.is_superuser ||
                userData?.user?.job_title?.includes("VDC Manager")) && (
                    <>
                        <li className={isPathActive("/admin/total-station") ||
                            isPathActive("/admin/vdc-admin-manager")
                            ? "nav-item active"
                            : "nav-item"}>
                            <div
                                className="nav-link"
                                onClick={() => {
                                    toggleMenuState("adminSubMenuVdcAdmin");
                                    navigate("/admin/total-station");
                                }}
                            >
                                <i className="ti-id-badge menu-icon"></i>
                                <span className="menu-title">
                                    VDC
                                </span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={stateMenu.adminSubMenuVdcAdmin}>
                                <ul className="nav flex-column sub-menu" >
                                    {arrayAdminFeatures?.includes(
                                        "Total Station Update Requested By"
                                    ) && (
                                            <li className="nav-item">
                                                {" "}
                                                <Link
                                                    className={
                                                        isPathActive(
                                                            "/admin/total-station"
                                                        )
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                    to="/admin/total-station"
                                                >
                                                    Total Station Update <br /> Requested By
                                                </Link>
                                            </li>
                                        )}
                                    {arrayAdminFeatures?.includes(
                                        "VDC Admin Manager"
                                    ) && (
                                            <li className="nav-item">
                                                {" "}
                                                <Link
                                                    className={
                                                        isPathActive(
                                                            "/admin/vdc-admin-manager"
                                                        )
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                    to="/admin/vdc-admin-manager"
                                                >
                                                    VDC Admin Manager
                                                </Link>
                                            </li>
                                        )}
                                </ul>
                            </Collapse>
                        </li>
                    </>)}

            {arrayAdminFeatures?.includes("Permissions Manager") && (
                <li className="nav-item">
                    <div
                        className={
                            isPathActive("/admin/permissions-manager")
                                ? "nav-link active"
                                : "nav-link"
                        }
                        onClick={() =>
                            navigate("/admin/permissions-manager")
                        }
                    >
                        <i className="ti-id-badge menu-icon"></i>
                        <span className="menu-title">
                            Permissions <Gap />
                            Manager
                        </span>
                    </div>
                </li>
            )}
        </>
    )
}

AdminSection.propTypes = {
    userData: PropTypes.any,
    stateMenu: PropTypes.any.isRequired,
    toggleMenuState: PropTypes.func.isRequired,
    isPathActive: PropTypes.func.isRequired,
}

