import React from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Proptypes from 'prop-types'
export default function BaseButton({ size = "md", isLoading = false, children, variant = "primary", type = "button", onClick = () => null }) {
    return (
        <Button size={size} variant={variant} type={type} disabled={isLoading} onClick={onClick}>
            {
                isLoading ? (
                    <><Spinner as="span" variant="light" animation="border" size="sm" /><span className="ml-2">Loading...</span></>
                ) : (
                    <span>{children}</span>
                )
            }
        </Button>
    )
}

BaseButton.propTypes = {
    size: Proptypes.oneOf(["sm", "lg", "md"]),
    variant: Proptypes.oneOf(["danger", "primary", "success", "link", "light", "dark"]).isRequired,
    isLoading: Proptypes.bool,
    children: Proptypes.node.isRequired,
    type: Proptypes.oneOf(["submit", "button"]).isRequired,
    onClick: Proptypes.func
}