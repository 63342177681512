import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setToken,
  removeToken,
} from "./../services/auth/AuthToken";
import isEmpty from "lodash/isEmpty";
import decode from "jwt-decode";
import moment from "moment";
import qs from "query-string";
import { RK_DIVISION_KEY } from "../constants/userInfo/userBusinessUnit";
const url = process.env.REACT_APP_BACKEND_URL;

//for optimize url on MDM Process or bulk process
const celeryURL = process.env.REACT_APP_CELERY_BACKEND_URL;

const refreshToken = () => {
  const payload = {
    refresh: getRefreshToken(),
  };
  axios
    .post(`${url}/api/v1/token/refresh/`, payload)
    .then((res) => {
      console.log({ res });
      const token = res?.data?.data;
      setToken(token);
      window.location.reload();
    })
    .catch((err) => {
      removeToken();
      window.location = "/login";
    });
};

// Checking business unit is exist or not
const businessUnit = localStorage.getItem(RK_DIVISION_KEY) !== null ? localStorage.getItem(RK_DIVISION_KEY) : '';

// This for base url optimize for MDM Process
export const baseURLCelery = axios.create({
  baseURL: `${celeryURL}/api/v1`,
  headers: {
    Authorization: getAccessToken() ? `JWT ${getAccessToken()}` : null,
  },
});

baseURLCelery.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.AppUrl = window.location.href;
    if (getAccessToken()) {
      const decoded = decode(getAccessToken());
      if (moment().isAfter(moment.unix(decoded.exp))) {
        // removeToken();
        // window.location.href = "/login";
        refreshToken();
      } else {
        return config;
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

baseURLCelery.interceptors.response.use(null, (err) => {
  if (err?.response?.status === 401) {
    refreshToken();
  }

  return Promise.reject(err);
});
// End of baseURL Optimize


export const baseURL = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    Authorization: getAccessToken() ? `JWT ${getAccessToken()}` : null,
    'CompanyId': `${businessUnit}`,
    // "Content-Type": "multipart/form-data",
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Headers":
    //   "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // "Access-Control-Allow-Origin": "*",
    // AppUrl: window.location.href,
  },
});

baseURL.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.AppUrl = window.location.href;
    if (getAccessToken()) {
      const decoded = decode(getAccessToken());
      if (moment().isAfter(moment.unix(decoded.exp))) {
        // removeToken();
        // window.location.href = "/login";
        refreshToken();
      } else {
        return config;
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

baseURL.interceptors.response.use(null, (err) => {
  if (err?.response?.status === 401) {
    refreshToken();
  }

  return Promise.reject(err);
});

// List of service URL

// Getting list of data
export const getFetchData = ({ url, params }) => {
  return baseURL
    .get(`${url}${params ? `?${qs.stringify(params)}` : ""}  `)
    .then((res) => res.data);
};

export const getUserList = () => {
  return baseURL.get(`/users/?page_size=1000`).then((res) => res.data);
};

export const microsoftAuth = (payload) => {
  return baseURL.post(`/microsoft/auth/`, payload).then((res) => res.data);
};

export const userLogin = (payload) => {
  return baseURL.post(`/login/`, payload).then((res) => res.data);
};

export const userRefresh = (payload) => {
  return baseURL.post(`/token/refresh/`, payload).then((res) => res.data);
};

export const getUserHistory = () => {
  return baseURL
    .get(`/AppHistory/?limit=1000&offset=0`)
    .then((res) => res.data);
};
export const getActivityDashboard = (payload) => {
  return baseURL
    .get(`/AppHistory/ActivityDashboard?${payload}`)
    .then((res) => res.data);
};

export const getActivityDashboardSpecificAppName = (app_name, sub_app_name) => {
  return baseURL
    .get(`/AppHistory/AppTracking/${app_name}/${sub_app_name}/`)
    .then((res) => res.data);
};

export const getJobListVista = (job_id) => {
  return baseURL
    .get(`/Search-Vista-Job-Autocomplete?job_number=${job_id}`)
    .then((res) => res.data);
};

export const getJobListGlobalShop = (job_id) => {
  return baseURL
    .get(`/Search-GS-Job-Autocomplete/${job_id}`)
    .then((res) => res.data);
};

export const getJobPhaseVista = (vista_id) => {
  return baseURL
    .get(`/Vista/get-vista-job-phase/${vista_id}`)
    .then((res) => res.data);
};

export const getBatchListVista = (vista_id) => {
  return baseURL
    .get(`/VistaID-Get-All-Batches/${vista_id}`)
    .then((res) => res.data);
};

export const getBatchListGlobalShop = (gs_id) => {
  return baseURL.get(`/GSID-Get-All-Batches/${gs_id}`).then((res) => res.data);
};

export const getBatchDetailVista = (vista_id, batch_id) => {
  return baseURL
    .get(`/VistaID-Get-Batch/${vista_id}/${batch_id}`)
    .then((res) => res.data);
};

export const getBatchDetailGlobalshop = (gs_id, batch_id) => {
  return baseURL
    .get(`/GSID-Get-Batch/${gs_id}/${batch_id}`)
    .then((res) => res.data);
};

export const getJobBatchSummary = (vista_id) => {
  return baseURL
    .get(`/Get-Job-Batch-Statistics/${vista_id}`)
    .then((res) => res.data);
};

export const getBatchTracker = (vista_id, batch_id) => {
  return baseURL
    .get(`/BatchTracker/BatchTracker?vista_id=${vista_id}&batch_pk=${batch_id}`)
    .then((res) => res.data);
};

export const updateJobBatchDelivery = (vista_id, batch_id, data) => {
  return baseURL
    .patch(`BatchTracker/Batch-Delivery-Status/${vista_id}/${batch_id}`, data)
    .then((res) => res.data);
};

export const getBatchTrackerSummaryDetail = (payload) => {
  return baseURL
    .post(`/BatchTracker/SummaryDetail/`, payload)
    .then((res) => res.data);
}

export const getBatchTrackerExport = (data) => {
  return baseURL.post(`/Export-Work-Orders`, data).then((res) => res.data);
};

export const getStagingList = (data) => {
  return baseURL
    .post(`/Staging/GetBatchesInStaging`, data)
    .then((res) => res.data);
};

export const getBatchListStaging = (vista_id, data) => {
  return baseURL
    .post(`/Staging/GetBatchesInStagingByJob/${vista_id}`, data)
    .then((res) => res.data);
};

export const getBatchTimelineStaging = (batch_pk) => {
  return baseURL
    .get(`/Staging/GetStagingTimeline/${batch_pk}`)
    .then((res) => res.data);
};

export const postTimelineStagingPost = (batch_pk, data) => {
  return baseURL
    .post(`/Staging/StageBatch/${batch_pk}`, data)
    .then((res) => res.data);
};

export const postTimelineStagingComment = (data) => {
  return baseURL.post(`/Staging/PostComment`, data).then((res) => res.data);
};

export const updateTimelineStagingPost = (post_pk, data) => {
  return baseURL
    .patch(`/Staging/EditStagedBatch/${post_pk}`, data)
    .then((res) => res.data);
};

export const updateTimelineStagingComment = (comment_pk, data) => {
  return baseURL
    .patch(`/Staging/EditComment/${comment_pk}`, data)
    .then((res) => res.data);
};

export const deleteTimelineStagingComment = (comment_pk) => {
  return baseURL
    .delete(`/Staging/EditComment/${comment_pk}`)
    .then((res) => res.data);
};

export const getStagingExport = (data) => {
  return baseURL.post(`/Staging/CSVExport`, data).then((res) => res.data);
};

export const getStagingSummary = () => {
  return baseURL.get(`/Staging/SummaryReport`).then((res) => res.data);
};

export const getMaterialSummary = (payload) => {
  return baseURL
    .get(`/MaterialRecords/MaterialBudget/summary?${payload}`)
    .then((res) => res.data);
};
export const getMaterialFieldSummary = (payload) => {
  return baseURL
    .get(`/MaterialRecords/MaterialBudget/field-summary?${payload}`)
    .then((res) => res.data);
};

export const getReturnRequestDetail = ({ return_requisition_number }) => {
  return baseURL
    .get(
      `/MaterialRecords/MaterialBudget/return-request-detail/${return_requisition_number}`
    )
    .then((res) => res.data);
};

export const getMaterialToolsHistory = (payload) => {
  return baseURL
    .get(`/MaterialRecords/MaterialBudget/tool-rentals?${payload}`)
    .then((res) => res.data);
};

export const getExportMaterialToolsHistory = ({
  job_number,
  page_size,
  page,
}) => {
  return baseURL
    .get(
      `/MaterialRecords/MaterialBudget/export-transaction-history?job_number=${job_number}&page_size=${page_size}&page=${page}`
    )
    .then((res) => res.data);
};

export const getExportMaterialRentalHistory = ({
  job_number,
  page_size,
  page,
}) => {
  return baseURL
    .get(
      `/MaterialRecords/MaterialBudget/UnitedRentals/export-transaction-history?job_number=${job_number}&page_size=${page_size}&page=${page}`
    )
    .then((res) => res.data);
};

export const getLockDateTaskScheduler = () => {
  return baseURL.get(`/TaskScheduler/lock-date`).then((res) => res.data);
};

export const getTaskSchedulerSummary = (payload) => {
  return baseURL
    .get(`/TaskScheduler/summary?${payload}`)
    .then((res) => res.data);
};
export const updateTaskScheduler = (payload) => {
  return baseURL
    .patch(`/TaskScheduler/update-task`, payload)
    .then((res) => res.data);
};
export const cancelTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/cancel-task?${payload}`)
    .then((res) => res.data);
};

export const getTaskSchedulerActivity = (payload) => {
  return baseURL
    .get(`/TaskScheduler/task-activity?task_id=${payload}`)
    .then((res) => res.data);
};

export const postLockDateTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/lock-date`, payload)
    .then((res) => res.data);
};
export const postTaskActivity = (payload) => {
  return baseURL
    .post(`/TaskScheduler/task-activity`, payload)
    .then((res) => res.data);
};

export const createAcceptTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/accept-task`, payload)
    .then((res) => res.data);
};

export const createApproveTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/approve-task`, payload)
    .then((res) => res.data);
};

export const createSubmitAcceptTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/submit-task`, payload)
    .then((res) => res.data);
};

export const createTaskScheduler = (payload) => {
  return baseURL
    .post(`/TaskScheduler/create-task`, payload)
    .then((res) => res.data);
};

export const getTaskList = (payload) => {
  return baseURL
    .get(`/TaskScheduler/list-task?${payload}`)
    .then((res) => res.data);
};
export const getCalendarData = (payload) => {
  return baseURL
    .get(`/TaskScheduler/calender-task?${payload}`)
    .then((res) => res.data);
};

export const getTradeList = () => {
  return baseURL.get(`/TaskScheduler/list-trade-task`).then((res) => res.data);
};

export const getTaskListDetail = (payload) => {
  return baseURL
    .post(`/TaskScheduler/detail-task/${payload}`)
    .then((res) => res.data);
};

export const getAppointmentList = (payload) => {
  return baseURL
    .get(`/TotalStationScheduler/AppointmentList?${payload}`)
    .then((res) => res.data);
};

export const getReschduleAppointmentRecap = (payload) => {
  // const reschedule_condition = (reschedule_available) ? `reschedule_available=true` : ``;
  // console.log(reschedule_condition);
  // if (isEmpty(job_number)) {
  //   return baseURL
  //     .get(`/TotalStationScheduler/AppointmentList?${reschedule_condition}&page=${page}&page_size=100`)
  //     .then((res) => res.data);
  // } else {
  //   return baseURL
  //     .get(
  //       `/TotalStationScheduler/AppointmentList?${reschedule_condition}&job_number=${job_number}&page=${page}&page_size=100`
  //     )
  //     .then((res) => res.data);
  // }
  return baseURL
    .get(`/TotalStationScheduler/RescheduledAppointmentRecap?${payload}`)
    .then((res) => res.data);
};

export const getAppointmentDetail = (id) => {
  return baseURL
    .get(`/TotalStationScheduler/AppointmentDetail/${id}`)
    .then((res) => res.data);
};

export const getLockDateTotalStation = () => {
  return baseURL
    .get(`/TotalStationScheduler/LockDate?page_size=1000`)
    .then((res) => res.data);
};

export const postLockDateTotalStation = (payload) => {
  return baseURL
    .post(`/TotalStationScheduler/LockDate`, payload)
    .then((res) => res.data);
};

export const getSchedulingList = ({
  month,
  year,
  page,
  page_size,
  date_range,
}) => {
  return baseURL
    .get(
      `/TotalStationScheduler/Scheduling?date_range=${date_range}&page=${page}`
    )
    .then((res) => res.data);
};

export const cancelAppointment = (payload) => {
  return baseURL
    .post(`TotalStationScheduler/CancelAppointment`, payload)
    .then((res) => res.data);
};

export const updateAppointmentRequester = (payload) => {
  return baseURL
    .post(`TotalStationScheduler/UpdateRequestedBy`, payload)
    .then((res) => res.data);
};

export const updateAppointment = (id, payload) => {
  return baseURL
    .patch(`TotalStationScheduler/UpdateAppointment/${id}`, payload)
    .then((res) => res.data);
};

export const rescheduleAppointment = (id, payload) => {
  return baseURL
    .patch(`TotalStationScheduler/Reschedule/${id}`, payload)
    .then((res) => res.data);
};

export const confirmAppointment = (id) => {
  return baseURL
    .post(`/TotalStationScheduler/ConfirmAppointment/${id}`)
    .then((res) => res.data);
};

export const createContact = (payload) => {
  return baseURL.post(`/Help/feedback/create`, payload).then((res) => res.data);
};
export const updateHelpTicket = (payload) => {
  return baseURL
    .patch(`/Help/feedback/update`, payload)
    .then((res) => res.data);
};
// export const getListHelpTicket = () => {
//   return baseURL
//     .get(`/Help/feedback/list?page=1&page_size=1000&sort_by=created_at:desc`)
//     .then((res) => res.data);
// };
export const getListHelpTicket = (queryParams) => {
  return baseURL
    .get(`/Help/feedback/list?${queryParams}`)
    .then((res) => res.data);
};


export const getListCategoryContact = () => {
  return baseURL.get("/Help/feedback/category").then((res) => res.data);
};

export const getJobDataReschedule = (vista_id) => {
  return baseURL
    .get(`/TotalStationScheduler/Reschedule/${vista_id}`)
    .then((res) => res.data);
};

export const CreateAppointment = (payload) => {
  return baseURL
    .post("/TotalStationScheduler/CreateAppointment", payload)
    .then((res) => res.data);
};

export const patchAddPoOrTransferNumberAndUpdateStatus = (
  requisition_id,
  payload
) => {
  return baseURL
    .patch(`/FieldCatalog/add-notes/${requisition_id}`, payload)
    .then((res) => res.data);
};

export const getRequisitionNumber = (payload) => {
  return baseURL
    .post("/MaterialRecords/MaterialBudget/submit-return-request", payload)
    .then((res) => res.data);
};

export const getMaterialRecordsImportLog = (payload) => {
  return baseURL.get(`/MaterialRecords/Import-Log?${payload}`).then((res) => res.data);
};

export const getCreateToolsReturn = (payload) => {
  return baseURL
    .patch(`​/MaterialRecords​/MaterialBudget​/submit-return-request`, payload)
    .then((res) => res.data);
};

export const getMaterialToolsTransactionHistory = (payload) => {
  return baseURL
    .get(`/MaterialRecords/MaterialBudget/transaction-history?${payload}`)
    .then((res) => res.data);
};

export const getMaterialTransactionHistoryRental = (payload) => {
  return baseURL
    .get(
      `MaterialRecords/MaterialBudget/UnitedRentals/rental-transaction-history?${payload}`
    )
    .then((res) => res.data);
};

export const getUnitedTransactionRental = (payload) => {
  return baseURL
    .get(
      `MaterialRecords/MaterialBudget/UnitedRentals/open-rental-transactions?${payload}`
    )
    .then((res) => res.data);
};

export const getUnitedRentalPrefill = (data) => {
  return baseURL
    .post(`/UnitedRentals/pickup-request-prefill`, data)
    .then((res) => res.data);
};

export const getMicrosoftGraph = (payload) => {
  return baseURL
    .get(
      `MicrosoftGraph/graph-employee-name-search/${payload?.name_str}/${payload?.check_internal}`
    )
    .then((res) => res.data);
};

export const getMicrosoftGraphUserInfo = (name) => {
  return baseURL
    .get(`MicrosoftGraph/graph-employee-name-search/${name}`)
    .then((res) => res.data);
};

export const createRequestReturnUnitedRental = (payload) => {
  return baseURL
    .post(`/UnitedRentals/pickup-request`, payload)
    .then((res) => res.data);
};

// export const getReceivingCounter = (page, page_size, location, po) => {
//   return baseURL
//     .get(
//       `/Receiving/DashboardCounter?page=${page}&page_size=${page_size}&location=${location}&po_string=${po}`
//     )
//     .then((res) => res.data);
// };
export const getReceivingCounter = (payload) => {
  return baseURL
    .get(`/Receiving/DashboardCounter?${payload}`)
    .then((res) => res.data);
};

export const getExportReceiving = (payload) => {
  if (!isEmpty(payload)) {
    return baseURL.get(`/Receiving/WeeklyReceivingReport`, {
      params: payload,
    });
  }
  return baseURL.get("/Receiving/WeeklyReceivingReport");
};

export const getReceivingReport = () => {
  return baseURL.get("/Receiving/WeeklyReceivingRKElectricalReport");
};

export const getExportReceivingProductionPlanning = () => {
  return baseURL.get("/Receiving/WeeklyReceivingProdPlanReport");
};

export const getBackorderJobs = (payload) => {
  return baseURL
    .get(`/Receiving/BackorderJobs?sort_by_job_number=${payload}`)
    .then((res) => res.data);
};

export const getBackorderVendors = (payload) => {
  return baseURL
    .get(`/Receiving/BackorderVendors?${payload}`)
    .then((res) => res.data);
};

export const getBackorderShippingLocations = (payload) => {
  return baseURL
    .get(`/Receiving/BackorderShippingLocations?${payload}`)
    .then((res) => res.data);
};

export const getBackorderWorkOrders = (payload) => {
  return baseURL
    .get(`/Receiving/BackorderWorkOrders?${payload}`)
    .then((res) => res.data);
};

export const getBackorderReport = (payload) => {
  return baseURL
    .get(`/Receiving/BackorderReport${payload}`)
    .then((res) => res.data);
};

export const getTrackingReport = (payload) => {
  return baseURL
    .get(`/Receiving/GenerateTrackingReceiptReport?${payload}`)
    .then((res) => res.data);
};

export const getReceivingList = (
  queryParams,
  page,
  page_size,
  status,
  location,
  sortStatus,
  sortDeliveryDate,
  sortPO,
  po_string
) => {
  return baseURL
    .get(`/Receiving/ReceivingHomePageList?${queryParams}`)
    .then((res) => res.data);
  // return baseURL
  //   .get(
  //     `/Receiving/ReceivingHomePageList?page=${page}&page_size=${page_size}&status=${status}&location=${location}&sort_by_status=${sortStatus}&sort_by_delivery_date=${sortDeliveryDate}&sort_by_po=${sortPO}&po_string=${po}`
  //   )
  //   .then((res) => res.data);
};

export const getReceivingByJob = (jobId) => {
  return baseURL
    .get(`/Receiving/ReceivingPurchaseOrder/${jobId}`)
    .then((res) => res.data);
};

export const getReceivingByBatch = (batchId) => {
  return baseURL
    .get(`/Receiving/ReceivingHomePageList?batch_pk=${batchId}`)
    .then((res) => res.data);
};

export const getReceivingDetail = (id) => {
  return baseURL
    .get(`/Receiving/PurchaseOrderDetails/${id}`)
    .then((res) => res.data);
};

export const updateReceivingDetail = (id, data) => {
  return baseURL
    .patch(`/Receiving/PurchaseOrderDetails/${id}`, data)
    .then((res) => res.data);
};

export const updateReceivingLine = (id, data) => {
  return baseURL
    .patch(`/Receiving/ReceivingPurchaseOrderLine/${id}`, data)
    .then((res) => res.data);
};

export const updateReceivingPO = (id, data) => {
  return baseURL
    .patch(`/Receiving/ReceivePurchaseOrder/${id}`, data)
    .then((res) => res.data);
};

export const createReceivingBatchImage = (id, data) => {
  return baseURL
    .post(`/Receiving/CreateReceivingBatchImage/${id}`, data)
    .then((res) => res.data);
};

export const updateReceivingBatchImage = (id, data) => {
  return baseURL
    .patch(`/Receiving/ReceivingBatchImage/${id}`, data)
    .then((res) => res.data);
};

export const deleteReceivingBatchImage = (id) => {
  return baseURL
    .delete(`/Receiving/ReceivingBatchImage/${id}`)
    .then((res) => res.data);
};

export const getReceivingMaterialOrder = (queryParams) => {
  return baseURL
    .get(`/Receiving/ReceivingMaterialOrderDetail?${queryParams}`)
    .then((res) => res.data);
};

export const getSpoolingDashboard = (jobNumber) => {
  return baseURL
    .get(`/Spooling/Dashboard?vista_job_number=${jobNumber}`)
    .then((res) => res.data);
};

export const getSpoolingList = (
  page,
  page_size,
  filterNumber,
  sortStatus,
  jobNumber
) => {
  return baseURL
    .get(
      `/Spooling/Batches?page=${page}&page_size=${page_size}&filter_by_number=${filterNumber}&sort_by=${
        jobNumber && sortStatus.includes("asc")
          ? "status:desc"
          : jobNumber && sortStatus.includes("desc")
          ? "status:asc"
          : !jobNumber && sortStatus
          ? sortStatus
          : null
      }&vista_job_number=${jobNumber}`
    )
    .then((res) => res.data);
};

export const getSpoolingInstallationList = (
  page,
  page_size,
  filterNumber,
  sortStatus,
  jobNumber
) => {
  return baseURL
    .get(
      `/Spooling/SpoolingInstallation?page=${page}&page_size=${page_size}&filter_by_number=${filterNumber}&sort_by_status=${
        jobNumber && sortStatus === "asc"
          ? "desc"
          : jobNumber && sortStatus === "desc"
          ? "asc"
          : !jobNumber && sortStatus
          ? sortStatus
          : null
      }&vista_job_number=${jobNumber}`
    )
    .then((res) => res.data);
};

export const postSpoolingInstallationsBim360Document = (
  fk_spooling_batch_id,
  data
) => {
  return baseURL
    .post(
      `/Spooling/SpoolingInstallation/${fk_spooling_batch_id}/BIM360Document/`,
      data
    )
    .then((res) => res.data);
};

export const getSpoolingInstallationsInstall = (fk_spooling_batch_id) => {
  return baseURL
    .get(`/Spooling/SpoolingInstallation/${fk_spooling_batch_id}/Install/`)
    .then((res) => res.data);
};

export const postSpoolingInstallationsInstall = (
  fk_spooling_batch_id,
  data
) => {
  return baseURL
    .post(
      `/Spooling/SpoolingInstallation/${fk_spooling_batch_id}/Install/`,
      data
    )
    .then((res) => res.data);
};

export const getSpoolingDetail = (spoolingId) => {
  return baseURL
    .get(`/Spooling/BatchesDetail/${spoolingId}`)
    .then((res) => res.data);
};

export const getSpoolingSummary = (queryParams) => {
  return baseURL
    .get(`/Spooling/SummaryReport?${queryParams}`)
    .then((res) => res.data);
};

export const getSpoolingSummaryDetail = (summaryId) => {
  return baseURL
    .get(`/Spooling/SummaryReportDetail/${summaryId}`)
    .then((res) => res.data);
};

export const postSpoolingRequest = (data) => {
  return baseURL.post(`/Spooling/Batches`, data).then((res) => res.data);
};

export const updateSpoolingRequest = (spoolingId, data) => {
  return baseURL
    .put(`/Spooling/BatchesDetail/${spoolingId}`, data)
    .then((res) => res.data);
};

export const postSpoolingReview = (spoolingId, data) => {
  return baseURL
    .post(`/Spooling/Review/${spoolingId}`, data)
    .then((res) => res.data);
};

export const postSpoolingRevision = (spoolingId, data) => {
  return baseURL
    .post(`/Spooling/Revision/${spoolingId}`, data)
    .then((res) => res.data);
};

export const getMaterialRecordList = (page, page_size, search_query) => {
  return baseURL
    .get(
      `/MaterialRecords/Material-Record?page=${page}&page_size=${page_size}&search_query=${search_query}`
    )
    .then((res) => res.data);
};

export const getMaterialRecordDetail = (materialId) => {
  return baseURL
    .get(`/MaterialRecords/Material-Record-Detail/${materialId}`)
    .then((res) => res.data);
};

export const createMaterialRecordDetail = (payload) => {
  return baseURL
    .post(`/MaterialRecords/Material-Record`, payload)
    .then((res) => res.data);
};

export const updateMaterialRecordDetail = (materialId, payload) => {
  return baseURL
    .patch(`/MaterialRecords/Material-Record-Detail/${materialId}`, payload)
    .then((res) => res.data);
};

export const deleteMaterialRecordBulk = (deletedId) => {
  return baseURL
    .get(`/MaterialRecords/Material-Record/Bulk-Delete/${deletedId}`)
    .then((res) => res.data);
};

export const getMaterialRecordExportCSV = (queryParams) => {
  return baseURL
    .get(`/MaterialRecords/Export-material-records?${queryParams}`)
    .then((res) => res.data);
};

export const postMaterialRecordImportCSV = (payload) => {
  return baseURL
    .post(`/MaterialRecords/import-material-records`, payload)
    .then((res) => res.data);
};

export const postNonRkPo = (data, batch_pk) => {
  return baseURL
    .post(`Receiving/NonRKBatchPurchaseOrder/${batch_pk}`, data)
    .then((res) => res.data);
};

export const postPoCorrections = (data, po_pk) => {
  return baseURL
    .post(`Receiving/PurchasingCorrections/${po_pk}`, data)
    .then((res) => res.data);
};

export const postReceivingPacking = (poId, payload) => {
  return baseURL
    .post(`/Receiving/createPackingListPDF/${poId}`, payload)
    .then((res) => res.data);
};

export const getMaterialManufacturerList = (page, page_size) => {
  return baseURL
    .get(
      `/MaterialRecords/Asset-Manufacturer?page=${page}&page_size=${page_size}`
    )
    .then((res) => res.data);
};

export const getMaterialVendorList = (page, page_size) => {
  return baseURL
    .get(`/MaterialRecords/Vendor?page=${page}&page_size=${page_size}`)
    .then((res) => res.data);
};

export const getVendorList = (page, page_size) => {
  return baseURL
    .get(`/VendorManager/ListVendor?page=${page}&page_size=${page_size}`)
    .then((res) => res.data);
};

export const getMultiplierCategory = (page, page_size) => {
  return baseURL
    .get(
      `/VendorManager/MultiplierCategory?page=${page}&page_size=${page_size}`
    )
    .then((res) => res.data);
};

export const postVendorManager = (payload) => {
  return baseURL
    .post(`/VendorManager/VendorManager`, payload)
    .then((res) => res.data);
};

export const getMaterialCategoryList = (page, page_size) => {
  return baseURL
    .get(`/MaterialRecords/Asset-Category?page=${page}&page_size=${page_size}`)
    .then((res) => res.data);
};

export const getMaterialSubcategoryList = (page, page_size) => {
  return baseURL
    .get(
      `/MaterialRecords/Asset-Subcategory?page=${page}&page_size=${page_size}`
    )
    .then((res) => res.data);
};

export const getMaterialFamilyList = (page, page_size) => {
  return baseURL
    .get(`/MaterialRecords/Asset-Family?page=${page}&page_size=${page_size}`)
    .then((res) => res.data);
};

export const postBatchDeliveryRequest = (payload) => {
  return baseURL
    .post(`/BatchScheduler/RequestDelivery`, payload)
    .then((res) => res.data);
};

export const getBatchSchedulerDeliveryDates = (start_week, end_week, data) => {
  return baseURL
    .get(`BatchScheduler/DeliveryDates/${start_week}/${end_week}`, data)
    .then((res) => res.data);
};

export const postReceivingDetailNotes = (poId, data) => {
  return baseURL
    .post(`Receiving/CreatePurchaseOrderNotes/${poId}`, data)
    .then((res) => res.data);
};

export const postReceivingDetailImages = (poId, data) => {
  return baseURL
    .post(`Receiving/CreatePurchaseOrderImages/${poId}`, data)
    .then((res) => res.data);
};

export const updateReceivingDetailNotes = (notesId, data) => {
  return baseURL
    .patch(`Receiving/UpdatePurchaseOrderNotes/${notesId}`, data)
    .then((res) => res.data);
};

export const postNotifyFieldEmail = (payload) => {
  return baseURL
    .post(`/BatchScheduler/NotifyFieldEmail`, payload)
    .then((res) => res.data);
};

export const getBatchSchedulerStagingList = (data, filter_by_number = "") => {
  return baseURL
    .post(
      `/BatchScheduler/StagedBatches?filter_by_number=${filter_by_number}`,
      data
    )
    .then((res) => res.data);
};

// export const getBatchSchedulerStagingList = (data, filter_by_number='', page='', page_size='', sort_by_status='') => {
//   return baseURL
//     .post(`/BatchScheduler/StagedBatches?filter_by_number=${filter_by_number}?page=${page}&page_size=${page_size}&sort_by_status=${sort_by_status}`, data)
//     .then((res) => res.data);
// };
export const getCreateMaterialRequisition = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/create?${queryParams}`)
    .then((res) => res.data);
};

export const postCreateMaterialRequisition = (payload) => {
  return baseURL
    .post(`/FieldCatalog/material-requisition/create`, payload)
    .then((res) => res.data);
};

export const patchUpdateMaterialRequisition = (uuId, payload) => {
  return baseURL
    .patch(`/FieldCatalog/${uuId}/edit-material-requisition`, payload)
    .then((res) => res.data);
};

export const patchRequisitionCancel = (payload) => {
  return baseURL
    .patch(`/FieldCatalog/material-requisition/cancel`, payload)
    .then((res) => res.data);
};

export const patchRequisitionCancelItem = (payload) => {
  return baseURL
    .patch(`/FieldCatalog/material-requisition/item/cancel`, payload)
    .then((res) => res.data);
};

export const postCreateMaterialRequisitionAddItem = (payload) => {
  return baseURL
    .post(`/FieldCatalog/material-requisition/add-item`, payload)
    .then((res) => res.data);
};

export const patchMaterialRequisitionUpdateQty = (payload) => {
  return baseURL
    .patch(`/FieldCatalog/material-requisition/item/update-qty`, payload)
    .then((res) => res.data);
};

export const patchMaterialRecordUpdate = (id, payload) => {
  return baseURL
    .patch(`/MaterialRecords/UpdateMaterialRecord/${id}`, payload)
    .then((res) => res.data);
};

export const getCreateMaterialRequisitionCart = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/cart?${queryParams}`)
    .then((res) => res.data);
};

export const getRequisitionLocation = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/get-ship-location?${queryParams}`)
    .then((res) => res.data);
};

export const getRequisitionDetail = (materialId) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/${materialId}/detail`)
    .then((res) => res.data);
};

export const getRequisitionActivities = (materialId, queryParams) => {
  return baseURL
    .get(`/FieldCatalog/requisition-activity/${materialId}?${queryParams}`)
    .then((res) => res.data);
};

export const getMaterialDetail = (materialId) => {
  return baseURL
    .get(`/FieldCatalog/material/${materialId}/detail`)
    .then((res) => res.data);
};

export const getMaterialRequisitionList = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/list?${queryParams}`)
    .then((res) => res.data);
};

export const getMaterialRequisitionSummary = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/summary?${queryParams}`)
    .then((res) => res.data);
};

export const postSubmitMaterialRequisition = (uuId, payload) => {
  return baseURL
    .patch(`/FieldCatalog/${uuId}/submit-material-requisition`, payload)
    .then((res) => res.data);
};

export const deleteItemRequisition = (uuId) => {
  return baseURL
    .delete(`/FieldCatalog/material-requisition/${uuId}/remove-item`)
    .then((res) => res.data);
};

export const postRequisitionApproval = (payload) => {
  return baseURL
    .post(`/FieldCatalog/material-requisition/approval`, payload)
    .then((res) => res.data);
};

export const postRequisitionFulfillment = (payload) => {
  return baseURL
    .post(`/FieldCatalog/material-requisition/fulfillment`, payload)
    .then((res) => res.data);
};

export const getReaderList = (queryParams) => {
  return baseURL.get(`/Readers/?${queryParams}`).then((res) => res.data);
};

export const getReaderDetail = (readerId) => {
  return baseURL.patch(`/Readers/${readerId}`).then((res) => res.data);
};

export const postCreateReader = (payload) => {
  return baseURL.post(`/Readers/`, payload).then((res) => res.data);
};

export const patchUpdateReader = (readerId, payload) => {
  return baseURL.patch(`/Readers/${readerId}`, payload).then((res) => res.data);
};

export const deleteReaderDetail = (readerId) => {
  return baseURL.delete(`/Readers/${readerId}`).then((res) => res.data);
};

export const getMaterialBudgetList = (jobId, queryParams) => {
  return baseURL
    .get(`/MaterialRecords/MaterialBudget/${jobId}?${queryParams}`)
    .then((res) => res.data);
};

export const postMaterialBudget = (payload) => {
  return baseURL
    .post(`/MaterialRecords/MaterialBudget`, payload)
    .then((res) => res.data);
};

export const getExportRequisition = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/material-requisition/export-csv?${queryParams}`)
    .then((res) => res.data);
};

export const getPhaseCodeAll = (queryParams) => {
  return baseURL
    .get(`/Vista/vista-job-phase-list?${queryParams}`)
    .then((res) => res.data);
};

export const getPhaseCodeSpecific = (queryParams) => {
  return baseURL
    .get(`/FieldCatalog/get-phase-code?${queryParams}`)
    .then((res) => res.data);
};

export const patchUpdatePhaseCodeItem = (itemId, payload) => {
  return baseURL
    .patch(
      `/FieldCatalog/material-requisition/item/${itemId}/update-phase-code`,
      payload
    )
    .then((res) => res.data);
};

export const getBatchPlanList = (queryParams) => {
  return baseURL
    .get(`/BatchApproval/BatchApprovalList?${queryParams}`)
    .then((res) => res.data);
};

export const getBatchPlanDetail = (itemId) => {
  return baseURL
    .get(`/BatchApproval/BatchApproval/detail/${itemId}`)
    .then((res) => res.data);
};

export const getBatchPlanSummary = (queryParams) => {
  return baseURL
    .get(`/BatchApproval/BatchApprovalSummary?${queryParams}`)
    .then((res) => res.data);
};

export const getBatchPlanCheckList = (queryParams) => {
  return baseURL
    .get(`/BatchApproval/GetQuestionList?${queryParams}`)
    .then((res) => res.data);
};

export const postBatchPlanCheckList = (payload) => {
  return baseURL
    .post(`/BatchApproval/CheckList`, payload)
    .then((res) => res.data);
};

export const postCreateBatchPlan = (payload) => {
  return baseURL
    .post(`/BatchApproval/CreateBatchApproval`, payload)
    .then((res) => res.data);
};

export const postResubmitBatchPlan = (id, payload) => {
  return baseURL
    .patch(`/BatchApproval/BatchApproval/resubmit/${id}`, payload)
    .then((res) => res.data);
};

export const patchUpdateBatchPlan = (itemId, payload) => {
  return baseURL
    .patch(`/BatchApproval/BatchApproval/update/${itemId}`, payload)
    .then((res) => res.data);
};

export const patchUpdateFieldContactBatchPlan = (id, payload) => {
  return baseURL
    .patch(`/BatchApproval/BatchApproval/change_person/${id}`, payload)
    .then((res) => res.data);
};

export const getBatchPlanLog = (itemId) => {
  return baseURL
    .get(`/BatchApproval/LogActivity/${itemId}`)
    .then((res) => res.data);
};

export const getBatchPlanCheck = (queryParams) => {
  return baseURL
    .get(`/BatchApproval/CheckBatchApproval?${queryParams}`)
    .then((res) => res.data);
};

export const getOtifVendorList = (queryParams) => {
  return baseURL
    .get(`/OTIFReport/vendor-list/?${queryParams}`)
    .then((res) => res.data);
};

export const getOtifVendorReport = (queryParams) => {
  return baseURL
    .get(`/OTIFReport/vendor-report/?${queryParams}`)
    .then((res) => res.data);
};

export const patchUpdateReqItemSingle = (item_id, payload) => {
  return baseURL
    .patch(`/FieldCatalog/update-cart-item/${item_id}`, payload)
    .then((res) => res.data);
};

export const deleteReqItemSingle = (item_id) => {
  return baseURL
    .delete(`/FieldCatalog/remove-cart-item/${item_id}`)
    .then((res) => res.data);
};

export const getAdminManagerList = (payload) => {
  return baseURL.get(`/admin-manager/?${payload}`).then((res) => res.data);
};
export const addAdminManagerList = (payload) => {
  return baseURL.post(`/admin-manager/`, payload).then((res) => res.data);
};
export const getApplicationList = (payload) => {
  return baseURL.get(`/application-list/?${payload}`).then((res) => res.data);
};
export const getPermissionList = (payload) => {
  return baseURL.get(`/permission-list?${payload}`).then((res) => res.data);
};
export const getPermission = (payload) => {
  return baseURL.get(`/permission/?${payload}`).then((res) => res.data);
};
export const postPermission = (payload) => {
  return baseURL.post(`/permission/`, payload).then((res) => res.data);
};
export const deletePermissionUser = (payload) => {
  return baseURL.post(`/remove-permission/`, payload).then((res) => res.data);
};
export const getBulkPO = (queryParams) => {
  return baseURL
    .get(`/Purchasing/PurchaseOrderList?${queryParams}`)
    .then((res) => res.data);
};
export const getDetailPO = (queryParams) => {
  return baseURL
    .get(`/Purchasing/CreateReleaseOrder?${queryParams}`)
    .then((res) => res.data);
};
export const getReleaseOrderDetail = (queryParams) => {
  return baseURL
    .get(`/Purchasing/ReleaseOrderDetail?${queryParams}`)
    .then((res) => res.data);
};
export const postCreateReleaseOrder = (payload) => {
  return baseURL
    .post(`/Purchasing/CreateReleaseOrder`, payload)
    .then((res) => res.data);
};

export const getInventoryProducts = (queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/product/search?${queryParams}`)
    .then((res) => res.data);
};

export const getInventoryCart = (queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/product/add-to-cart?${queryParams}`)
    .then((res) => res.data);
};

export const postInventoryAddToCart = (payload) => {
  return baseURL
    .post(`/InventoryCatalog/product/add-to-cart`, payload)
    .then((res) => res.data);
};

export const getInventoryVdcJobListVista = (job_id) => {
  return baseURL
    .get(`/Search-Vista-Job-Autocomplete?job_number=${job_id}`)
    .then((res) => res.data);
};

export const getInventoryRequisitionList = (queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/?${queryParams}`)
    .then((res) => res.data);
};

export const getInventoryRequisitionActivity = (reqId) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${reqId}/activity_log/`)
    .then((res) => res.data);
};

export const getInventoryRequisitionDetail = (reqId) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${reqId}/`)
    .then((res) => res.data);
};

export const postInventoryImportVDC = (payload) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/import-csv`, payload)
    .then((res) => res.data);
};

export const postInventoryMaterialHeader = (payload) => {
  return baseURL
    .post(`/InventoryCatalog/material-order/create_header/`, payload)
    .then((res) => res.data);
};

export const postInventoryMaterialSubmit = (payload) => {
  return baseURL
    .post(`/InventoryCatalog/material-order/submit/`, payload)
    .then((res) => res.data);
};

export const updateInventoryMaterialItems = (payload) => {
  return baseURL
    .post(`/InventoryCatalog/material-order/update_item/`, payload)
    .then((res) => res.data);
};

export const deleteItemInventory = (id) => {
  return baseURL
    .delete(`/InventoryCatalog/material-order/${id}/remove_item/`)
    .then((res) => res.data);
};

export const getInventoryMaterialList = (queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/material-order/?${queryParams}`)
    .then((res) => res.data);
};

export const getInventoryMaterialDetail = (id) => {
  return baseURL
    .get(`/InventoryCatalog/material-order/${id}/`)
    .then((res) => res.data);
};

export const updateInventoryMaterialDetail = (id, payload) => {
  return baseURL
    .patch(`/InventoryCatalog/material-order/${id}/`, payload)
    .then((res) => res.data);
};

export const postInventoryMaterialConfirm = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/material-order/${id}/confirm/`, payload)
    .then((res) => res.data);
};

export const deleteInventoryMaterial = (id, payload) => {
  return baseURL
    .delete(`/InventoryCatalog/material-order/${id}/remove_draft/`, payload)
    .then((res) => res.data);
};

export const getInventoryRequisitionSummary = (queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/summary/`)
    .then((res) => res.data);
};

export const postInventoryVDCImportDifferentRequisition = (id) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/${id}/import_difference/`)
    .then((res) => res.data);
};

export const getInventoryVDCReImportRequisition = (id) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${id}/re_import/`)
    .then((res) => res.data);
};

export const getInventoryVDCStageRequisition = (id) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${id}/staged/`)
    .then((res) => res.data);
};

export const postInventoryMaterialImages = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/material-order/${id}/add_image/`, payload)
    .then((res) => res.data);
};

export const postInventoryVDCSubmit = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/${id}/submit/`, payload)
    .then((res) => res.data);
};

export const postInventoryVDCFulfillment = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/${id}/fulfillment_items/`, payload)
    .then((res) => res.data);
};

export const postInventoryVDCConfirmation = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/${id}/confirm_items/`, payload)
    .then((res) => res.data);
};

export const updateInventoryVDC = (id, payload) => {
  return baseURL
    .patch(`/InventoryCatalog/vdc-requisition/${id}/`, payload)
    .then((res) => res.data);
};

export const deleteInventoryVDCItem = (id) => {
  return baseURL
    .delete(`/InventoryCatalog/items/${id}/`)
    .then((res) => res.data);
};

export const updateInventoryVDCItem = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/items/${id}/update_item/`, payload)
    .then((res) => res.data);
};

export const postInventoryVDCNotes = (id, payload) => {
  return baseURL
    .post(`/InventoryCatalog/vdc-requisition/${id}/add_notes/`, payload)
    .then((res) => res.data);
};

export const getInventoryVDCReport = (id) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${id}/generate_report/`)
    .then((res) => res.data);
};

export const getInventoryCSV = (id, queryParams) => {
  return baseURL
    .get(`/InventoryCatalog/vdc-requisition/${id}/get_csv/?${queryParams}`)
    .then((res) => res.data);
};

export const deleteInventoryDiff = (id) => {
  return baseURL
    .delete(`/InventoryCatalog/vdc-requisition/${id}/remove_difference_requisition/`)
    .then((res) => res.data);
}

export const getCeleryProgress = (id) => {
  return baseURL.post(`/celery-progress/${id}`).then((res) => res.data);
};
