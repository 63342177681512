import { lazy } from "react";
import { ADMIN_IMPORT_MAPPED_RECORDS } from "../constants/mappingRecords/link";
import { SUPPLY_CHAIN_MANAGER_LINK } from "../constants/supplyChainManager/link";

const MyLogin = lazy(() => import("./../pages/Auth/Login"));
const MyRegister = lazy(() => import("./../pages/Auth/Register"));
const ForgotPassword = lazy(() => import("./../pages/Auth/ForgotPassword"));
const ChangePassword = lazy(() => import("./../pages/Auth/ChangePassword"));
const ResetPassword = lazy(() => import("./../pages/Auth/ResetPassword"));
const AuthCallback = lazy(() => import("./../pages/Auth/AuthCallback"));
const ContactUS = lazy(() => import("./../pages/Contact/index"));
const HelpTicket = lazy(() => import("../pages/Admin/HelpTicket/HelpTicket"));

const Dashboard = lazy(() => import("./../pages/Dashboard/Dashboard"));
const BatchTracker = lazy(() => import("./../pages/BatchTracker/BatchTracker_v2"));
const BatchTrackerCsvExport = lazy(() =>
  import("./../pages/BatchTracker/CsvExport")
);

const StagingList = lazy(() => import("./../pages/Staging/List"));
const TotalStation = lazy(() => import("../pages/TotalStation/TotalStation"));
const AdminTotalStation = lazy(() => import("../pages/Admin/TotalStation"));
const CreateAppointment = lazy(() =>
  import("../pages/TotalStation/CreateAppointment")
);

const SummaryReport = lazy(() =>
  import("./../pages/TotalStation/SummaryReport")
);

const UpdateAppointment = lazy(() =>
  import("../pages/TotalStation/UpdateAppointment")
);
const ConfirmAppointment = lazy(() =>
  import("../pages/TotalStation/ConfirmAppointment")
);
const AssignmentLayoutForeman = lazy(() =>
  import("../pages/TotalStation/AssignmentLayout")
);
const RescheduleAppointment = lazy(() =>
  import("../pages/TotalStation/RescheduleAppointment")
);
const DetailAppointment = lazy(() =>
  import("../pages/TotalStation/DetailAppointment")
);
const CalendarAppointment = lazy(() =>
  import("../pages/TotalStation/Calendar")
);
const StagingDetail = lazy(() => import("./../pages/Staging/Detail"));
const StagingCsvExport = lazy(() => import("./../pages/Staging/CsvExport"));
const StagingSummary = lazy(() => import("./../pages/Staging/Summary"));

const Receiving = lazy(() => import("./../pages/Receiving/Receiving"));
const ReceivingCSVExport = lazy(() => import("./../pages/Receiving/Export"));
const PlanningReport = lazy(() =>
  import("./../pages/Receiving/PlanningReport")
);
const TrackingReceipt = lazy(() =>
  import("./../pages/Receiving/TrackingReceipt")
);
const BackorderReport = lazy(() => import("./../pages/Receiving/BackOrder"));
const BackorderResult = lazy(() =>
  import("./../pages/Receiving/BackOrder/BackOrderResult")
);
const ReceivingSummary = lazy(() => import("./../pages/Receiving/Summary"));
const ReceivingDetail = lazy(() => import("./../pages/Receiving/Detail"));
const OtifReport = lazy(() => import("./../pages/Receiving/OtifReport"));

const SpoolingList = lazy(() => import("./../pages/Spooling/List"));
const SpoolingSummary = lazy(() => import("./../pages/Spooling/Summary"));
const SpoolingSummaryDetail = lazy(() =>
  import("./../pages/Spooling/SummaryDetail")
);

const BatchPlanList = lazy(() => import("./../pages/BatchPlan/List"));

const MaterialRecordManager = lazy(() =>
  import("./../pages/MaterialRecordManager/MaterialRecordManager")
);
const MaterialRecordForm = lazy(() =>
  import("./../pages/MaterialRecordManager/MaterialRecordForm")
);
const MaterialCsvExport = lazy(() =>
  import("./../pages/MaterialRecordManager/CsvExport")
);
const MaterialCsvImport = lazy(() =>
  import("./../pages/MaterialRecordManager/CsvImport")
);

const Settings = lazy(() => import("./../pages/Settings/Settings"));
const EditProfile = lazy(() => import("./../pages/Auth/EditProfile"));

const BatchScheduler = lazy(() =>
  import("./../pages/BatchScheduler/BatchScheduler")
);

const ToolsReturn = lazy(() => import("./../pages/ToolsReturn"));

const FieldCatalog = lazy(() => import("../pages/FieldCatalog/FieldCatalog"));
const CreateFieldCatalog = lazy(() =>
  import("../pages/FieldCatalog/CreateMaterial")
);

const MasteringProductSearch = lazy(() =>
  import("../pages/Admin/AdminProductSearch/MaterialList")
);
const MasteringProductSearchElastic = lazy(() =>
  import("../pages/Admin/AdminProductSearch/MaterialListElastic")
);

const SupplyChainManager = lazy(() =>
  import("../pages/Admin/SupplyChainManager")
);

const MasteringProductDetail = lazy(() =>
  import("../pages/Admin/AdminProductSearch/MaterialDetail")
);
const MaterialMasteringForm = lazy(() =>
  import("../pages/Admin/AdminProductSearch/MasteringProductSearch")
);
const BulkRecordImport = lazy(() =>
  import(
    "../pages/Admin/BulkRecordMastering/BulkImportRecords/BulkRecordImport"
  )
);
const BulkExistingRecordImport = lazy(() =>
  import(
    "../pages/Admin/BulkRecordMastering/BulkImportRecords/BulkExistingRecordImport"
  )
);
const BulkRecordExport = lazy(() =>
  import(
    "../pages/Admin/BulkRecordMastering/BulkExportRecords/BulkRecordExport"
  )
);
const MappingRecord = lazy(() =>
  import("../pages/Admin/MappingRecordMastering/MappingRecord/MappingRecord")
);
const ExportUnmappedRecords = lazy(() =>
  import("../pages/Admin/MappingRecordMastering/ExportUnmappedRecords/ExportUnmappedRecords")
);
const ImportMappedRecord = lazy(() =>
  import("../pages/Admin/BulkImportMapped")
);
const MaterialMasteringHome = lazy(() =>
  import("../pages/Admin/AdminProductSearch/MaterialMasteringHome")
);
const NewRecordTemplate = lazy(() =>
  import("../pages/Admin/BulkRecordMastering/BulkExportRecords/NewRecordTemplate")
);

const SupplyChainSearch = lazy(() =>
  import("../pages/SupplyChainSearch/MaterialList")
);

const MaterialFieldCatalogDetail = lazy(() =>
  import("../pages/SupplyChainSearch/MaterialDetail")
);
const MaterialFieldCatalogCart = lazy(() =>
  import("../pages/SupplyChainSearch/MaterialCart")
);
const RequisitionFieldCatalog = lazy(() =>
  import("../pages/FieldCatalog/RequisitionList")
);

const Readers = lazy(() => import("../pages/Readers/Readers"));
const ReadersCreate = lazy(() => import("../pages/Readers/FormReaders"));

const AdminJobSetup = lazy(() => import("../pages/Admin/JobSetup/JobSetup"));
const AdminJobSetupForm = lazy(() =>
  import("../pages/Admin/JobSetup/FormJobSetup")
);
const AdminMaterialImport = lazy(() => import("../pages/Admin/MaterialLog"));
const AdminActivityDashboard = lazy(() =>
  import("../pages/Admin/ActivityDashboard/ActivityDashboard")
);
const VdcAdminManager = lazy(() =>
  import("../pages/Admin/VdcAdminManager/VdcAdminManager")
);
const PermissionManager = lazy(() =>
  import("../pages/Admin/PermissionManager/PermissionManager")
);

const VendorManager = lazy(() => import("../pages/Admin/VendorManager"));

const TaskScheduler = lazy(() => import("../pages/TaskScheduler/TaskList"));

const TaskSchedulerAccept = lazy(() =>
  import("../pages/TaskScheduler/TaskAccept")
);

const TaskSchedulerDetail = lazy(() =>
  import("../pages/TaskScheduler/TaskDetail")
);
const TaskSchedulerCreate = lazy(() => import("../pages/TaskScheduler/Create"));
const TaskSchedulerEdit = lazy(() => import("../pages/TaskScheduler/Edit"));
const TaskSchedulerCalendar = lazy(() =>
  import("../pages/TaskScheduler/Calendar")
);

const Summary = lazy(() => import("../pages/Summary/Summary"));
const FieldSummary = lazy(() => import("../pages/FieldSummary"));
const ReturnRental = lazy(() => import("../pages/Summary/ReturnRequestUnited"));
// const SummaryTools = lazy(() => import("../pages/Summary/SummaryTools"));

// const StationScheduler = lazy(() => import("./../pages/StationScheduler/StationScheduler"));

const VDCRequisition = lazy(() =>
  import("./../pages/Inventory/VDCRequisition")
);
const VDCImport = lazy(() => import("./../pages/Inventory/VDCImport"));
const SearchInventory = lazy(() =>
  import("./../pages/Inventory/SearchInventory")
);
const CreateMaterialOrder = lazy(() =>
  import("./../pages/Inventory/CreateMaterialOrder")
);
const DetailReqVDC = lazy(() => import("./../pages/Inventory/DetailReqVDC"));
const DetailReqVDCMaterial = lazy(() => import("./../pages/Inventory/MaterialDetail"));
const MaterialOrders = lazy(() =>
  import("./../pages/Inventory/MaterialOrders")
);
const SupplyChainReports = lazy(() =>
  import("../pages/Admin/SupplyChainReports/SupplyChainReports")
);

export const routeList = [
  {
    exact: true,
    path: "/login",
    component: MyLogin,
  },
  {
    exact: true,
    path: "/register",
    component: MyRegister,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    exact: true,
    path: "/change-password",
    component: ChangePassword,
  },
  {
    exact: true,
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    exact: true,
    path: "/microsoft/auth-callback",
    component: AuthCallback,
  },
];

export const privateRouteList = [
  {
    exact: true,
    path: "/dashboard",
    component: Dashboard,
  },
  {
    exact: true,
    path: "/batch-tracker/app",
    component: BatchTracker,
  },
  {
    exact: true,
    path: "/batch-tracker/csv-export",
    component: BatchTrackerCsvExport,
  },
  {
    exact: true,
    path: "/staging/app",
    component: StagingList,
  },
  {
    exact: true,
    path: "/staging/app/:batchId",
    component: StagingDetail,
  },
  {
    exact: true,
    path: "/staging/csv-export",
    component: StagingCsvExport,
  },
  {
    exact: true,
    path: "/staging/summary",
    component: StagingSummary,
  },
  {
    exact: true,
    path: "/receiving/app",
    component: Receiving,
  },
  {
    exact: true,
    path: "/receiving/csv-export",
    component: ReceivingCSVExport,
  },
  {
    exact: true,
    path: "/receiving/receiving-for-production-planning",
    component: PlanningReport,
  },
  {
    exact: true,
    path: "/receiving/tracking-receipt-report",
    component: TrackingReceipt,
  },
  {
    exact: true,
    path: "/receiving/backorder-report",
    component: BackorderReport,
  },
  {
    exact: true,
    path: "/receiving/backorder-report/result",
    component: BackorderResult,
  },
  {
    exact: true,
    path: "/receiving/summary/:batchId",
    component: ReceivingSummary,
  },
  {
    exact: true,
    path: "/receiving/detail/:poId",
    component: ReceivingDetail,
  },
  {
    exact: true,
    path: "/receiving/otif-report",
    component: OtifReport,
  },
  {
    exact: true,
    path: "/spooling/app",
    component: SpoolingList,
  },
  {
    exact: true,
    path: "/spooling/summary",
    component: SpoolingSummary,
  },
  {
    exact: true,
    path: "/spooling/summary/:jobId",
    component: SpoolingSummaryDetail,
  },
  {
    exact: true,
    path: "/batch-plan/app",
    component: BatchPlanList,
  },
  {
    exact: true,
    path: "/admin/material-records",
    component: MaterialRecordManager,
  },
  {
    exact: true,
    path: "/supply-chain/reports",
    component: SupplyChainReports,
  },
  {
    exact: true,
    path: "/materialrecords/add",
    component: MaterialRecordForm,
  },
  {
    exact: true,
    path: "/admin/materialrecords/:materialId/edit",
    component: MaterialRecordForm,
  },
  {
    exact: true,
    path: "/materialrecords/csv-export",
    component: MaterialCsvExport,
  },
  {
    exact: true,
    path: "/material-records/csv-import",
    component: MaterialCsvImport,
  },
  {
    exact: true,
    path: "/batch-scheduler",
    component: BatchScheduler,
  },
  {
    exact: true,
    path: "/settings",
    component: Settings,
  },
  {
    exact: true,
    path: "/edit-profile",
    component: EditProfile,
  },
  {
    exact: true,
    path: "/catalog",
    component: FieldCatalog,
  },
  {
    exact: true,
    path: "/catalog/create",
    component: CreateFieldCatalog,
  },
  {
    exact: true,
    path: "/catalog/create/:uuId",
    component: CreateFieldCatalog,
  },
  {
    exact: true,
    path: "/catalog/:uuId/material",
    component: SupplyChainSearch,
  },
  {
    exact: true,
    path: "/catalog/:uuId/material/:materialId",
    component: MaterialFieldCatalogDetail,
  },
  {
    exact: true,
    path: "/catalog/material",
    component: SupplyChainSearch,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/search-materials",
    component: SupplyChainSearch,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/:uuId/material",
    component: SupplyChainSearch,
  },
  {
    exact: true,
    path: "/catalog/material/:materialId",
    component: MaterialFieldCatalogDetail,
  },

  {
    exact: true,
    path: "/catalog/cart/:uuId",
    component: MaterialFieldCatalogCart,
  },
  {
    exact: true,
    path: "/catalog/cart",
    component: MaterialFieldCatalogCart,
  },
  {
    exact: true,
    path: "/catalog/requisition",
    component: RequisitionFieldCatalog,
  },
  {
    exact: true,
    path: "/readers",
    component: Readers,
  },
  {
    exact: true,
    path: "/readers/create",
    component: ReadersCreate,
  },
  {
    exact: true,
    path: "/readers/:readerId/edit",
    component: ReadersCreate,
  },
  {
    exact: true,
    path: "/admin/job-setup",
    component: AdminJobSetup,
  },
  {
    exact: true,
    path: "/admin/material-import-log",
    component: AdminMaterialImport,
  },
  {
    exact: true,
    path: "/admin/catalog/material",
    component: MasteringProductSearchElastic,
  },
  {
    exact: true,
    path: "/admin/catalog/material/search",
    component: MasteringProductSearchElastic,
  },
  {
    exact: true,
    path: SUPPLY_CHAIN_MANAGER_LINK,
    component: SupplyChainManager,
  },
  {
    exact: true,
    path: "/admin/catalog/material/:materialId",
    component: MasteringProductDetail,
  },
  {
    exact: true,
    path: "/admin/catalog/mastering/:uuId",
    component: MaterialMasteringForm,
  },
  {
    exact: true,
    path: "/admin/mastering/bulk-records/import",
    component: BulkRecordImport,
  },
  {
    exact: true,
    path: "/admin/mastering/bulk-existing-records/import",
    component: BulkExistingRecordImport,
  },
  {
    exact: true,
    path: "/admin/mastering/bulk-records/export",
    component: BulkRecordExport,
  },
  {
    exact: true,
    path: "/admin/mastering/new-record-template/export",
    component: NewRecordTemplate,
  },
  {
    exact: true,
    path: "/admin/mastering/map-single-record",
    component: MappingRecord,
  },
  {
    exact: true,
    path:ADMIN_IMPORT_MAPPED_RECORDS,
    component: ImportMappedRecord,
  },
  {
    exact: true,
    path:"/admin/mastering/export-unmapped-records",
    component: ExportUnmappedRecords,
  },
  {
    exact: true,
    path: "/admin/mastering-material/home",
    component: MaterialMasteringHome,
  },
  {
    exact: true,
    path: "/admin/activity-dashboard",
    component: AdminActivityDashboard,
  },
  {
    exact: true,
    path: "/admin/vdc-admin-manager",
    component: VdcAdminManager,
  },
  {
    exact: true,
    path: "/admin/permissions-manager",
    component: PermissionManager,
  },
  {
    exact: true,
    path: "/admin/total-station",
    component: AdminTotalStation,
  },
  {
    exact: true,
    path: "/admin/vendor-manager",
    component: VendorManager,
  },
  {
    exact: true,
    path: "/admin/job-setup/create",
    component: AdminJobSetupForm,
  },
  {
    exact: true,
    path: "/admin/job-setup/:budgetId/edit",
    component: AdminJobSetupForm,
  },
  {
    exact: true,
    path: "/admin/summary",
    component: Summary,
  },
  {
    exact: true,
    path: "/field-summary",
    component: FieldSummary,
  },
  {
    exact: true,
    path: "/tools_return",
    component: ToolsReturn,
  },
  {
    exact: true,
    path: "/total-station-scheduler/summary-report",
    component: SummaryReport,
  },
  {
    exact: true,
    path: "/task-scheduler/create",
    component: TaskSchedulerCreate,
  },
  {
    exact: true,
    path: "/task-scheduler/list/edit/:id",
    component: TaskSchedulerEdit,
  },
  {
    exact: true,
    path: "/task-scheduler/list/accept/:id",
    component: TaskSchedulerAccept,
  },
  {
    exact: true,
    path: "/task-scheduler/list/detail/:id",
    component: TaskSchedulerDetail,
  },
  {
    exact: true,
    path: "/task-scheduler/list",
    component: TaskScheduler,
  },
  {
    exact: true,
    path: "/task-scheduler/calendar",
    component: TaskSchedulerCalendar,
  },
  {
    exact: true,
    path: "/total-station-scheduler/app",
    component: TotalStation,
  },
  {
    exact: true,
    path: "/contact-us",
    component: ContactUS,
  },
  {
    exact: true,
    path: "/admin/help-tickets",
    component: HelpTicket,
  },
  {
    exact: true,
    path: "/total-station-scheduler/create",
    component: CreateAppointment,
  },
  {
    exact: true,
    path: "/total-station-scheduler/update/:id",
    component: UpdateAppointment,
  },
  {
    exact: true,
    path: "/total-station-scheduler/assignment-layout-foreman/:id",
    component: AssignmentLayoutForeman,
  },
  {
    exact: true,
    path: "/total-station-scheduler/calendar",
    component: CalendarAppointment,
  },
  {
    exact: true,
    path: "/total-station-scheduler/reschedule/:id?",
    component: RescheduleAppointment,
  },
  {
    exact: true,
    path: "/total-station-scheduler/confirm/:id?",
    component: ConfirmAppointment,
  },
  {
    exact: true,
    path: "/total-station-scheduler/detail/:id",
    component: DetailAppointment,
  },
  {
    exact: true,
    path: "/summary/return-request-united",
    component: ReturnRental,
  },
  {
    exact: true,
    path: "/inventory/vdc-requisition",
    component: VDCRequisition,
  },
  {
    exact: true,
    path: "/inventory/vdc-import",
    component: VDCImport,
  },
  {
    exact: true,
    path: "/inventory/create-material",
    component: CreateMaterialOrder,
  },
  {
    exact: true,
    path: "/inventory/create-material/:id",
    component: CreateMaterialOrder,
  },
  {
    exact: true,
    path: "/inventory/material-orders",
    component: MaterialOrders,
  },
  {
    exact: true,
    path: "/inventory/search",
    component: SearchInventory,
  },
  {
    exact: true,
    path: "/inventory/search/:id",
    component: SearchInventory,
  },
  {
    exact: true,
    path: "/inventory/detail/:id",
    component: DetailReqVDC,
  },
  {
    exact: true,
    path: "/inventory/detail/material/:materialId",
    component: DetailReqVDCMaterial,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/material-orders",
    component: MaterialOrders,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/requisition-list",
    component: MaterialOrders,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/requisition-list/vdc-requisition",
    component: VDCRequisition,
  },
  {
    exact: true,
    path: "/supply-chain-catalog/requisition-list/material-requisition",
    component: RequisitionFieldCatalog,
  }
];
